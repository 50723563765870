<template>
    <b-modal v-model="isOpen" content-class="rounded-0" dialog-class="long-modal" header-class="border-0"
        header-bg-variant="light" hide-header-close centered no-close-on-backdrop no-close-on-esc hide-footer>
        <template #modal-title> Editar Agente Residente </template>
        <div v-if="user !== null">
            <b-row class="mt-2">
                <b-col>
                    <b-form-group>
                        <label class="form-title" label-for="name-input">
                            Nombre*
                        </label>
                        <b-form-input id="name-input" v-model="userForm.name" size="sm" readonly class="rounded-0" />
                    </b-form-group>
                    <b-form-group class="mt-3">
                        <label class="form-title" label-for="name-input">Phone*</label>
                        <b-form-input id="name-input" v-model="userForm.phone" size="sm" required class="rounded-0" />
                    </b-form-group>

                </b-col>
            </b-row>
        </div>
        <b-row class="m-3 text-end">
            <b-col>
                <b-button size="sm" variant="danger" class="px-3 ms-4" @click="close">
                    Cerrar
                </b-button>
                <b-button size="sm" class="main-btn px-3 ms-4" @click="save">
                    Aceptar
                </b-button>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import { RepositoryFactory } from "@/services/api";

export default {
    name: "update-user-modal",
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        userEntity: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            userForm: null,
            user: null
        };
    },
    mounted() {
        this.user = JSON.parse(JSON.stringify(this.userEntity));
        this.userForm = {
            id: this.user.id,
            name: this.user.name,
            phone: this.user.phone
        };
    },
    computed: {

    },
    methods: {
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        async save() {
            try {
                const residentAgent = this.userForm;
                let payload = {
                    name: residentAgent.name,
                    phone: residentAgent.phone
                };
                await RepositoryFactory.residentAgent.update(residentAgent.id, payload);

                this.setModalInfo({
                    title: "Persona jurídica actualizada correctamente",
                    message:
                        "Se han actualizado correctamente los datos de la persona jurídica",
                    subMessage: "",
                    routeName: "legal-entities",
                });
                this.close();
            } catch {
                this.setModalInfo({
                    title: "Error actualizando persona jurídica",
                    message:
                        "Se ha producido un error intentando actualizar los datos de la persona jurídica",
                    subMessage:
                        "Por favor, revise que ha introducido datos correctos y vuelva a intentarlo",
                });
            }
            this.openModal();
        },
        close() {
            this.$emit("close");
        },
    },
};
</script>