<template>
    <div class="mt-5 px-xl-5">
        <b-row class="mt-2 mb-3">
            <h6 class="page-title pb-2">PERFIL DE ROL</h6>
            <b-col xl="5" md="7" class="d-flex mb-3">
                <b-card no-header no-body class="shadow-sm card-tab px-3"
                    v-bind:class="{ active: selectedCard == 'user-data' }" id="user-data" @click="setActive($event)">
                    <label class="card-tab-title">
                        Perfil de usuario
                        <b-icon-person class="ms-1"></b-icon-person>
                    </label>
                </b-card>
                <b-card no-header no-body class="shadow-sm card-tab ms-3 px-3"
                    v-bind:class="{ active: selectedCard == 'invitations' }" id="invitations"
                    @click="setActive($event)">
                    <label class="card-tab-title">
                        Invitaciones de roles
                        <b-icon-person-check class="ms-1"></b-icon-person-check>
                    </label>
                </b-card>
            </b-col>
        </b-row>
        <UserData v-if="selectedCard == 'user-data'" />
        <ResidentAgentsInvitations v-if="selectedCard == 'invitations'" />
    </div>
</template>

<script>
import UserData from "./UserData.vue";
import ResidentAgentsInvitations from "./ResidentAgentsInvitations.vue";

export default {
    name: "resident-agent-profile",
    components: {
        UserData,
        ResidentAgentsInvitations,
    },
    data() {
        return {
            selectedCard: "user-data",
        };
    },
    methods: {
        setActive(e) {
            this.selectedCard = e.currentTarget.id;
        },
    },
};
</script>

<style scoped>
.card-tab {
    padding: 0.4rem 0;
    border-radius: 0;
}

.card-tab-title {
    font-weight: 500;
    color: #717171;
    text-align: center;
}

.card-tab.active {
    background: rgb(232 243 255);
    border: 2px solid #406892;
}

.card-tab.active .card-tab-title {
    color: #406892;
}
</style>