<template>
    <div>
        <b-row class="mt-3 justify-content-end">
            <b-col md="5" lg="4" xl="3" class="text-end mb-3">
                <b-input-group>
                    <b-form-input
                        placeholder="Buscar"
                        class="rounded-0"
                        size="sm"
                        v-model="search"
                        @keydown.enter="filterTable"
                    ></b-form-input>
                    <b-input-group-append>
                        <b-button @click="filterTable" class="main-btn" size="sm">
                            <b-icon-search></b-icon-search>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>
        <b-row class="mb-5">
            <b-col>
                <b-card no-header no-body class="rounded-0 shadow-sm border-0">
                    <b-table
                        id="invitations-table"
                        small
                        stacked="md"
                        select-mode="multi"
                        selectable
                        no-select-on-click
                        show-empty
                        empty-text="No hay invitaciones que mostrar"
                        :filter="filter"
                        :busy.sync="isBusy"
                        :current-page="currentPage"
                        :per-page="take"
                        :items="retrieveData"
                        :fields="fields"
                        @filtered="onFiltered"
                    >
                        <template #table-busy>
                            <div class="text-center text-primary my-2">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                        </template>
                    </b-table>
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="take"
                        size="sm"
                        class="mb-3 me-3 mt-2 justify-content-end"
                    >
                        <template #first-text>
                            <span
                                ><b-icon-chevron-double-left
                                    scale="1.3"
                                ></b-icon-chevron-double-left
                            ></span>
                        </template>
                        <template #prev-text>
                            <span
                                ><b-icon-chevron-left
                                    scale="1.3"
                                ></b-icon-chevron-left
                            ></span>
                        </template>
                        <template #next-text>
                            <span
                                ><b-icon-chevron-right
                                    scale="1.3"
                                ></b-icon-chevron-right
                            ></span>
                        </template>
                        <template #last-text>
                            <span
                                ><b-icon-chevron-double-right
                                    scale="1.3"
                                ></b-icon-chevron-double-right
                            ></span>
                        </template>
                    </b-pagination>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import moment from "moment";
import { RepositoryFactory } from "@/services/api";

export default {
    name: "resident-agents-invitations",
    data() {
        return {
            fields: [
                { key: "email", label: "Email" },
                { key: "residentAgentType", label: "Tipo de Rol" },
                { key: "creationDate", label: "Fecha de envio" },
                { key: "used", label: "Consumido" },
                { key: "useDate", label: "Fecha de consumo" },
            ],
            search: "",
            filter: null,
            isBusy: false,
            currentPage: 1,
            take: 10,
            rows: 1,
        };
    },
    methods: {
        filterTable() {
            this.filter = this.search;
        },
        onFiltered(filteredItems) {
            this.rows = filteredItems.data.total;
            this.currentPage = 1;
        },
        async retrieveData(context) {
            this.isBusy = true;
            try {
                let payload = {
                    page: context.currentPage,
                    take: 10,
                    search: context.filter,
                };
                let response =
                    await RepositoryFactory.residentAgent.getPagedInvitations(
                        payload
                    );
                this.rows = response.data.total;
                let items = response.data.items;

                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    item.creationDate = this.parseAndConvertDate(
                        item.creationDate
                    );
                    item.residentAgentType = this.getResidentAgentString(
                        item.residentAgentType
                    );
                    item.useDate =
                        item.useDate === null
                            ? "-"
                            : this.parseAndConvertDate(item.useDate);
                    item.used = item.used ? "Sí" : "No";
                }
                return items;
            } catch (error) {
                window.console.log(error);
            }
            this.isBusy = false;
        },
        getResidentAgentString(type) {
            switch (type) {
                case 0:
                    return "Abogado";
                case 1:
                    return "Asistente";
                case 2:
                    return "Interno";
                case 3:
                    return "Oficial de cumplimiento";
            }
            return "";
        },
        parseAndConvertDate(date) {
            return moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss");
        },
    },
};
</script>

<style scoped>
@media (max-width: 768px) {
    ::v-deep #invitations-table > tbody > tr > td[aria-colindex="1"] input {
        margin-left: 5rem;
    }
}
</style>