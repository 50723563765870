<template>
    <b-row>
        <b-col>
            <b-card border-variant="light" class="shadow-sm rounded-0 mb-5" header-class="border-0">
                <template #header></template>
                <b-card-text>
                    <b-row class="justify-content-center m-2" v-if="loading">
                        <b-spinner variant="primary"></b-spinner>
                        <p class="text-center text-primary mt-2">
                            Cargando datos del agente residente
                        </p>
                    </b-row>
                    <b-row v-if="!loading" class="my-2">
                        <b-col class="text-center mb-4" lg="2">
                            <b-icon-person-circle font-scale="8" class="card-title-color"></b-icon-person-circle>
                            <b-button class="main-btn" size="sm" @click="openEdit">
                                <b-icon-pencil-square />
                            </b-button>
                            <!--aqui va el QR-->
                        </b-col>

                        <b-col v-if="residentAgent !== null" lg="4" md="6" class="ps-md-5 details-wrap">
                            <b-row class="mb-2">
                                <b-col sm="4">
                                    <label class="details-title">Nombre</label>
                                </b-col>
                                <b-col>
                                    <span class="details-title-value">{{
                        residentAgent.name
                    }}</span>
                                </b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col sm="4">
                                    <label class="details-title">Nacionalidad</label>
                                </b-col>
                                <b-col>
                                    <span class="details-title-value">{{
                            residentAgent.nationality
                        }}</span>
                                </b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col sm="4">
                                    <label class="details-title">Tipo de identificación</label>
                                </b-col>
                                <b-col>
                                    <span class="details-title-value">{{
                            residentAgent.identificationType
                        }}</span>
                                </b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col sm="4">
                                    <label class="details-title">Identificación</label>
                                </b-col>
                                <b-col>
                                    <span class="details-title-value">{{
                            residentAgent.identification
                        }}</span>
                                </b-col>
                            </b-row>
                            <b-row v-if="residentAgent.eligibilityNumber !==
                        undefined
                        " class="mb-2">
                                <b-col sm="4">
                                    <label class="details-title">Número de idoneidad</label>
                                </b-col>
                                <b-col>
                                    <span class="details-title-value">{{
                        residentAgent.eligibilityNumber
                    }}</span>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col v-if="residentAgent !== null" lg="4" md="6" class="ps-md-5 details-wrap mb-3">
                            <b-row class="mb-2">
                                <b-col sm="4">
                                    <label class="details-title">Email</label>
                                </b-col>
                                <b-col>
                                    <span class="details-title-value">{{
                        residentAgent.email
                    }}</span>
                                </b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col sm="4">
                                    <label class="details-title">Fecha de nacimiento</label>
                                </b-col>
                                <b-col>
                                    <span class="details-title-value">{{
                            parseDate(residentAgent.birthDate)
                        }}</span>
                                </b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col sm="4">
                                    <label class="details-title">Nombre del bufete</label>
                                </b-col>
                                <b-col>
                                    <span class="details-title-value">{{
                            residentAgent.lawFirmName.toUpperCase()
                        }}</span>
                                </b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col sm="4">
                                    <label class="details-title">CUR</label>
                                </b-col>
                                <b-col>
                                    <span class="details-title-value">{{
                            residentAgent.cur
                        }}</span>
                                </b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col sm="4">
                                    <label class="details-title">Phone</label>
                                </b-col>
                                <b-col>
                                    <span class="details-title-value">{{
                            residentAgent.phone
                        }}</span>
                                </b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col sm="4">
                                    <label class="details-title">Administrador</label>
                                </b-col>
                                <b-col>
                                    <span class="details-title-value">{{
                            residentAgent.isAdmin ? "Si" : "No"
                        }}</span>
                                </b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col sm="4">
                                    <label class="details-title">Fecha de invitación</label>
                                </b-col>
                                <b-col>
                                    <span class="details-title-value">{{
                            parseDate(residentAgent.invDate)
                        }}</span>
                                </b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col sm="4">
                                    <label class="details-title">Fecha de Creación</label>
                                </b-col>
                                <b-col>
                                    <span class="details-title-value">{{
                            parseDate(residentAgent.creationDate)
                        }}</span>
                                </b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col sm="4">
                                    <label class="details-title">Fecha de Primera Carga de Sociedades </label>
                                </b-col>
                                <b-col>
                                    <span v-if="parseDate(residentAgent.firstLoadDate) !== '01/01/0001'"
                                        class="details-title-value">{{
                                        parseDate(residentAgent.firstLoadDate)
                                        }}</span>
                                    <span v-if="parseDate(residentAgent.firstLoadDate) == '01/01/0001'"
                                        class="details-title-value">{{
                                        "-"
                                        }}</span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>
        </b-col>
        <UpdateUserModal v-if="residentAgent !== null" :isOpen="isOpenModal" :userEntity="residentAgent"
            @close="closeEdit" />
    </b-row>
</template>

<script>
import moment from "moment";
import { RepositoryFactory } from "@/services/api";
import UpdateUserModal from "../resident-agent-profile/UpdateUserModal.vue";

export default {
    name: "user-data",
    components: {
        UpdateUserModal
    },
    data() {
        return {
            loading: false,
            residentAgent: null,
            isOpenModal: false
        };
    },
    async mounted() {
        this.retrieveResidentAgent();
    },
    methods: {
        async retrieveResidentAgent() {
            this.loading = true;
            try {
                let response =
                    await RepositoryFactory.residentAgent.getByUser();
                let residentAgent = response.data;

                residentAgent.identificationType =
                    this.getIdentificationTypeString(
                        residentAgent.identificationType
                    );
                this.residentAgent = residentAgent;
            } catch (error) {
                window.console.log(error);
            }
            this.loading = false;
        },
        parseDate(date) {
            return moment(date).format("DD/MM/YYYY");
        },
        getIdentificationTypeString(type) {
            switch (type) {
                case 0:
                    return this.$t("general.cedula");
                case 1:
                    return this.$t("general.passport");
            }
            return "-";
        },
        openEdit() {
            this.isOpenModal = true;
        },
        closeEdit() {
            this.isOpenModal = false;
        }
    },
};
</script>

<style scoped>
.card-header {
    padding: 1.5rem 1rem !important;
}

@media (max-width: 768px) {
    .details-wrap {
        text-align: center;
    }
}
</style>
